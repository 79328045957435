body {
    background-color: #111;
    background-attachment: fixed;
    background-image: url('./img/grille.jpg');
    background-position: 0 0;
    background-repeat: repeat;
}

.App {
  width: 90%;
  margin: 0 auto;
  padding-bottom: 24px;
}

header {
  text-align: center;
}

.logo {
  padding: 24px 0;
}

.logo a {
  display: inline-block;
}

.logo-img {
  max-width: 100%;
  width: auto;
  height: 150px;
}

.content {
  padding: 5px 0;
  /*border: 2px solid #f6f7f8;*/
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;

  background-color: #ebedf0; 
}

.center {
  text-align: center;
}

.playlist-now-info h3 {
  margin: 5px 0;
}

.playlist-now-info h3:first-child {
  margin-top: 0;
}

.playlist-now-info p {
  margin: 0 0 5px;
}

h1, h2 {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

a, a:hover, a:active {
    color: #000;
}


#event, #announcement {
    background-color: #dedede;
    text-align: center;
    font-size: 1.25em;
    margin: -5px 0 10px;
    padding: 5px 0;
    /*height: 38px;*/
}

#announcement a {
    text-decoration: underline;
}

#announcement a:hover {
    text-decoration: none;
}

#now, #now div {
    overflow: auto;
}

.playlist-now {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 12px;
    grid-row-gap: 0px;
}

.playlist-now-art {
    grid-area: 1 / 1 / 2 / 2;
    text-align: right;
}

.playlist-now-info {
    grid-area: 1 / 2 / 2 / 3;
    text-align: left;
}
.playlist-now-art-cover {
    width: 150px;
    height: 150px;
    display: inline-block;
}

table {
  max-width: 100%;
  padding-left: 25px;
  padding-right: 25px;

  border-spacing: 0;
  font-size: 14px;
}

table .table-gray {
  background-color: #cfcfcf;
}

table.table-mobile {
  margin: 0 auto;
}

table td {
  word-break: break-all;
  user-select: all;
}

th, td {
  padding: 8px;
}

table.table-desktop th:nth-child(1),
table.table-desktop td:nth-child(1) {
  padding-left: 12px;
  text-align: center;
}

table.table-desktop th:nth-child(5),
table.table-desktop td:nth-child(5) {
  padding-right: 12px;
  text-align: center;
}

table.table-mobile th:nth-child(1),
table.table-mobile td:nth-child(1) {
  padding: 0 6px;
  text-align: center;
  width: 15%;
}

table.table-mobile th:nth-child(3),
table.table-mobile td:nth-child(3) {
  padding: 0 6px;
  text-align: center;
  width: 10%;
}

th.col-time {
  text-align: left;
}

th.col-artist,
th.col-track,
th.col-album {
  text-align: left;
}

.table-desktop th.col-time {
  width: 10%;
}

.table-desktop th.col-artist,
.table-desktop th.col-track,
.table-desktop th.col-album {
  width: 30%;
}

.table-mobile td.col-time,
.table-mobile td.col-duration {
  text-align: center;
}

.table>thead>tr>th {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.table>tbody>tr>td {
  border-top: 1px solid #ddd;
}

table .table-mobile th,
table .table-mobile td {
  vertical-align: middle;
}

.table-striped>tbody>tr:nth-child(odd)>td, .table-striped>tbody>tr:nth-child(odd)>th {
    background-color: #f9f9f9;
}

.recent-track-artist {
  font-weight: 500;
}

.recent-track-type {
  font-weight: normal;
}

.recent-track-type-id,
.recent-track-type-psa {
  color: white;
  padding: 2px 3px;
  border-radius: 4px;
}

.recent-track-type-id {
  background: green;
}

.recent-track-type-psa {
  background: purple;
}

.recent-track-song {
  font-size: 14px;
  font-weight: normal;
}

.recent-track-album {
  font-style: italic;
  font-weight: normal;
}

.load-tracks {
  text-align: center;
  margin: 1em 0;
}

.load-tracks button {
  width: 50%;
  height: 28px;
  font-size: 1em;
  font-weight: bold;
  background-color: #bbb;
}

footer {
    background-color: #272727;
    color: #999;
    border-top: 2px solid #000;
    padding: 10px 0;
    text-align: center;
    font-size: 1em;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    font-weight: bold;
}

@media screen and (max-width: 960px) {
  .App {
    width: 95%;
  }

  table {
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media screen and (min-width: 769px) {
  table.table-mobile {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  table.table-desktop {
    display: none;
  }
}
